import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path : '' , loadChildren : ()=> import("./components/component.module")
      .then(m => m.ComponentModule)
  },
  {
    path : 'school' , loadChildren : ()=> import("./components/layouts/ressources/schools-section/school.module")
      .then(m => m.SchoolModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
