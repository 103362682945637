import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import AOS from 'aos'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  title = 'bmlsms-portal';
  lang : string = '';

  constructor(private translateService: TranslateService) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('lang') || 'en');
  }

  ngOnInit() {
    this.lang = localStorage.getItem('lang')  || 'en';
    AOS.init({disable: 'mobile'});//AOS - 2
    AOS.refresh();
    
  }
}
