import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsModule } from './shared/icons/icons.module';
import {HttpClient, provideHttpClient, withInterceptors, withInterceptorsFromDi} from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgxSpinnerComponent} from "ngx-spinner";
import {loadingInterceptor} from "./shared/interceptors/loading.interceptor";
import {provideRouter} from "@angular/router";

export function HttpLoaderFactory(http : HttpClient){
  return new TranslateHttpLoader(http , 'src/assets/i18n/' , '.json');
}
@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        IconsModule,
        // NgAnimatedCounterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxSpinnerComponent
    ], providers: [
        provideAnimationsAsync(),
        provideHttpClient(),
        provideHttpClient(withInterceptors([loadingInterceptor]))
    ] })
export class AppModule {}
